:root {
  --fc-border-color: rgb(204, 204, 204);
  --fc-button-text-color: #313638;
  --fc-button-bg-color: #ffffff;
  --fc-button-border-color: rgb(63, 62, 62);
  --fc-button-hover-bg-color: #ffffff;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #ffffff;
  --fc-button-active-border-color: rgb(63, 62, 62);
  --fc-small-font-size: 15px;
}

.fc-day-future {
  transition: all 1s ease;
}

.fc-day-future:hover {
  background: #fff1e6;
  cursor: pointer;
}

.fc-day-today {
  cursor: pointer;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  max-width: 180px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 0px 5px 10px !important;
}

.fc-daygrid-day-events {
  min-height: 68px !important;
}

.fc-day-past {
  background: #dfdfdf;
}
